import React from 'react';

const PictureView = () => {
  return (
    <div>
        <div dangerouslySetInnerHTML={{ __html: `<iframe name="opinionstage-widget" src="https://www.opinionstage.com/api/v2/widgets/a38cdacc-0178-4999-8196-ba1ce1624f2f/iframe" data-opinionstage-iframe="a38cdacc-0178-4999-8196-ba1ce1624f2f" width="100%" height="800" scrolling="auto" style="border: none;" frameBorder="0" allow="fullscreen" webkitallowfullscreen="true" mozallowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
` }}></div>
    </div>
  );
};

export default PictureView;