import './App.css';
import Header from './components/Header';
import ImageViewer from './components/ImageViewer';
import PictureView from './components/PictureView';
import img1 from './assets/images/1.png';
import img2 from './assets/images/2.png';
import img3 from './assets/images/3.png';

function App() {
  //const imageUrl = 'https://pe-images.s3.amazonaws.com/basics/cc/image-size-resolution/resize-images-for-print/image-cropped-8x10.jpg';
  //const caption = 'A beautiful landscape';
  const images= [
    img1,img2,img3
  ];

  return (
    <div className="app-container">
      <Header/>      
      {/* <ImageViewer images={images} />       */}
      <PictureView/>
    </div>
  );
}

export default App;
