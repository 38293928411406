import React from 'react';
import '../css/Header.css';

const Header = () => {
  return (
    <div className="header">
      <h1 className="title">Youth Club of Loyola</h1>
      <p>Kalugumalai</p>
    </div>
  );
};

export default Header;
